/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import {
  Container,
  Slide1,
  Slide2,
  Slide3,
  Slide4,
  Slide5,
  Slide6,
} from './styles';

import reception from '~/assets/tv/end-of-year/reception.png';
import brownLogo from '~/assets/tv/end-of-year/brown-logo.svg';
import coiKidsReception from '~/assets/tv/end-of-year/coi-kids-reception.png';
import room from '~/assets/tv/end-of-year/room.png';

import whiteLogo from '~/assets/tv/end-of-year/white-logo.svg';
import happyHolidays from '~/assets/tv/end-of-year/happy-holidays.svg';
import doctors from '~/assets/tv/end-of-year/doctors.png';

import lipFillers from '~/assets/tv/end-of-year/lip-fillers.png';
import happyHolidays2 from '~/assets/tv/end-of-year/happy-holidays-2.svg';
import glitter from '~/assets/tv/end-of-year/glitter.png';

import botox from '~/assets/tv/end-of-year/botox.png';
import glitter2 from '~/assets/tv/end-of-year/glitter-2.png';

import before from '~/assets/tv/end-of-year/before.png';
import after from '~/assets/tv/end-of-year/after.png';
import arrow from '~/assets/tv/end-of-year/arrow.svg';
import glitter3 from '~/assets/tv/end-of-year/glitter-3.png';

import before2 from '~/assets/tv/end-of-year/before-2.png';
import after2 from '~/assets/tv/end-of-year/after-2.png';
import glitter4 from '~/assets/tv/end-of-year/glitter-4.png';

interface IEndOfYear {
  show: boolean;
  startAnim: boolean;
}

interface ISlide {
  slide: string;
  slideTime: number;
  beforeTime?: number;
  afterTime?: number;
  bgSlide?: string;
}

const EndOfYear: React.FC<IEndOfYear> = ({ show, startAnim }) => {
  const [slideSelected, setSlideSelected] = useState({} as ISlide);
  const [showBefore, setShowBefore] = useState('');
  const [animSlideSelected, setAnimSlideSelected] = useState('');
  const [endAnimSlideSelected, setEndAnimSlideSelected] = useState('');

  const slides = useMemo<ISlide[]>(
    () => [
      {
        slide: 'Slide1',
        slideTime: 7500,
      },
      // { slide: 'Slide2', slideTime: 5000 },
      { slide: 'Slide3', slideTime: 20000 },
      { slide: 'Slide4', slideTime: 20000 },
      { slide: 'Slide5', slideTime: 12500 },
      { slide: 'Slide6', slideTime: 10000 },
    ],
    []
  );

  const changeSlide = useCallback(
    (index) => {
      const { slide, slideTime } = slides[index];

      if (slides[index + 1] && slides[index + 1].beforeTime) {
        const nextSlide = slides[index + 1];
        if (nextSlide.beforeTime)
          setTimeout(() => {
            setShowBefore(nextSlide.slide);
            setAnimSlideSelected(nextSlide.slide);
          }, slideTime - nextSlide.beforeTime);
      }

      if (slides[index - 1] && slides[index - 1].afterTime) {
        const prevSlide = slides[index - 1];
        if (prevSlide.afterTime) {
          setEndAnimSlideSelected(prevSlide.slide);
          setSlideSelected(slides[index]);
          setTimeout(() => {
            setShowBefore('');
            setEndAnimSlideSelected('');
          }, prevSlide.afterTime);
        }
      } else {
        setSlideSelected(slides[index]);
      }
      setTimeout(() => {
        setAnimSlideSelected(slide);
        if (index + 1 < slides.length) {
          setTimeout(() => {
            changeSlide(index + 1);
          }, slideTime);
        } else {
          setTimeout(() => {
            setSlideSelected({} as ISlide);
          }, slideTime);
        }
      }, 100);
    },
    [slides]
  );

  useEffect(() => {
    if (show) {
      changeSlide(0);
    }
  }, [changeSlide, show]);

  return (
    <Container show={show} startAnim={startAnim}>
      <Slide1
        show={slideSelected.slide === 'Slide1'}
        startAnim={slideSelected.slide === 'Slide1'}
        notShow={slideSelected.slide !== 'Slide1'}
      >
        <div className="slide-container">
          <img src={reception} alt="Recepção" className="reception" />
          <div className="box">
            <h2 className="title mb-0">
              Uma estrutura diferenciada para com o melhor da Odontologia, para
              você e sua família
            </h2>
            <img src={brownLogo} alt="Logo" className="logo" />
          </div>
          <img src={coiKidsReception} alt="Coi Kids" className="coi-kids" />
          <img src={room} alt="Consultório" className="room" />
        </div>
      </Slide1>
      <Slide2
        show={slideSelected.slide === 'Slide2'}
        startAnim={slideSelected.slide === 'Slide2'}
      >
        <div className="slide-container">
          <div className="bg-light-brown" />
          <div className="bg-dark-brown" />
          <img src={whiteLogo} alt="logo" className="logo" />
          <img
            src={happyHolidays}
            alt="Boas Festas"
            className="happy-holidays"
          />
          <p className="content">
            Comece o ano com um <span>sorriso</span>{' '}
            <span>que te representa</span>
          </p>
          <img src={doctors} alt="Doutores" className="doctors" />
        </div>
      </Slide2>
      <Slide3
        show={slideSelected.slide === 'Slide3'}
        startAnim={slideSelected.slide === 'Slide3'}
      >
        <div className="slide-container">
          <img src={glitter} alt="Brilhos" className="glitter" />
          <img
            src={lipFillers}
            alt="Preenchimento labial"
            className="lip-fillers"
          />
          <div className="content">
            <p className="title">Preenchimento labial</p>
            <p className="text">
              Sentir-se confiante e radiante nunca foi tão fácil. Imagine chegar
              às festas de fim de ano com lábios mais volumosos e um olhar
              rejuvenescido.
            </p>
            <p className="text">
              O preenchimento labial e a toxina botulínica são os segredos para
              você se sentir ainda mais bonita(o).
            </p>
            <p className="cta">Agende agora e prepare-se para brilhar!</p>
          </div>
        </div>
      </Slide3>
      <Slide4
        show={slideSelected.slide === 'Slide4'}
        startAnim={slideSelected.slide === 'Slide4'}
      >
        <div className="slide-container">
          <img src={glitter2} alt="Brilhos" className="glitter" />
          <div className="content">
            <p className="title">Botox®</p>
            <p className="text">
              Transforme sua autoestima e prepare-se para um novo ciclo.
            </p>
            <p className="text">
              O preenchimento labial e a toxina botulínica podem te proporcionar
              resultados incríveis e naturais. <br />
              Sinta-se mais confiante e feliz com a sua imagem
            </p>
            <div className="d-flex flex-wrap">
              <p className="cta">Suaviza Marcas de expressão</p>
              <p className="cta">Previne rugas</p>
              <p className="cta">Resultados rápidos</p>
            </div>
          </div>
          <img src={botox} alt="Botox" className="botox" />
        </div>
      </Slide4>
      <Slide5
        show={slideSelected.slide === 'Slide5'}
        startAnim={slideSelected.slide === 'Slide5'}
      >
        <div className="slide-container">
          <img src={glitter} alt="Brilhos" className="glitter" />
          <img src={before} alt="Antes" className="before" />
          <img src={after} alt="Depois" className="after" />
          <img src={arrow} alt="Seta" className="arrow" />
          <div className="content">
            <p className="title">Clareamento Dental</p>
            <p className="text">
              Ilumine seu Sorriso neste Fim de Ano com Nosso Clareamento Dental!
            </p>
            <p className="text-small">
              Suas fotos ficarão mais lindas, com seu sorriso brancos. Fim de
              ano especial na Coi Prime
            </p>
          </div>
        </div>
      </Slide5>
      <Slide6
        show={slideSelected.slide === 'Slide6'}
        startAnim={slideSelected.slide === 'Slide6'}
      >
        <div className="slide-container">
          <img src={glitter4} alt="Brilhos" className="glitter" />
          <div className="content">
            <p className="title">Lentes de contato</p>
            <p className="text">Um novo sorriso, uma nova versão de você</p>
            <div className="d-flex flex-wrap">
              <p className="cta">Sorriso harmônico</p>
              <p className="cta">Preservação dental</p>
              <p className="cta">Durabilidade e resistência</p>
            </div>
          </div>
          <img src={before2} alt="Antes" className="before" />
          <p className="before-text">Antes</p>
          <img src={after2} alt="Depois" className="after" />
          <p className="after-text">Depois</p>
        </div>
      </Slide6>
    </Container>
  );
};

export default EndOfYear;
